@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --swiper-navigation-color: #f17104;
  --swiper-theme-color: #f17104;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Nunito Sans", -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  font-display: swap;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.swiper-button-next,
.swiper-button-prev {
  cursor: pointer;
  color: white !important;
}

.swiper-button-next,
.swiper-button-prev {
  top: calc(50% - 12px);
  width: 24px !important;
  height: 24px !important;
  background-color: var(--swiper-navigation-color, var(--swiper-theme-color));
  border-radius: 100%;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 14px !important;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background-color: #fe5171;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  display: none;
}

.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: var(
    --swiper-navigation-color,
    var(--swiper-theme-color)
  ) !important;
}

input[type="search"] {
  -webkit-appearance: none;
}

.gradient-text {
  background: -webkit-linear-gradient(#f17104, #fe5171);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@layer components {
  .bg-gradient {
    @apply bg-gradient-to-r from-safety-orange to-fiery-rose;
  }

  .bg-gradient-hover {
    @apply hover:from-fiery-rose hover:to-safety-orange;
  }

  .btn-primary {
    @apply px-4 py-3 justify-center font-semibold bg-gradient-to-r from-safety-orange to-fiery-rose hover:from-fiery-rose hover:to-safety-orange text-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-maize-crayola focus:border-maize-crayola;
  }

  .btn-secondary {
    @apply inline-flex items-center px-4 py-3 justify-center font-semibold text-safety-orange bg-gradient-to-r from-safety-orange-80 to-fiery-rose-80 hover:from-fiery-rose-80 hover:to-safety-orange-80 focus:outline-none focus:ring-1 focus:ring-fiery-rose focus:border-fiery-rose;
  }

  .btn-black {
    @apply inline-block px-4 py-3 rounded-md justify-center font-semibold font-medium text-white bg-gummetal focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500;
  }

  .btn-white {
    @apply rounded-md border border-gray-300 px-4 py-3 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-safety-orange focus:border-safety-orange;
  }

  .link {
    @apply underline text-safety-orange hover:text-fiery-rose cursor-pointer;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

.range-slider .range-slider__range {
  background: var(
    --swiper-navigation-color,
    var(--swiper-theme-color)
  ) !important;
  opacity: 0.5;
}

.range-slider .range-slider__thumb {
  width: 20px !important;
  height: 20px !important;
  background: var(
    --swiper-navigation-color,
    var(--swiper-theme-color)
  ) !important;
}

.html-insert ol,
.html-insert ul,
.html-insert menu {
  list-style: disc;
  padding-left: 1rem;
}

[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  border-color: #f17104;
}

.border-0 [class*="-control"] {
  border: 0;
  box-shadow: transparent;
}

.background-animate {
  background-size: 200%;

  -webkit-animation: AnimationName 1.5s ease infinite;
  -moz-animation: AnimationName 1.5s ease infinite;
  animation: AnimationName 1.5s ease infinite;
}

@keyframes AnimationName {
  0%,
  100% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }
}

.reset-style,
.reset-style * {
  all: revert;
}

.big-icon {
  font-size: 42px !important;
}
